import React from "react";


// Customizable Area Start
import { styled, Box, Grid, Typography, Container, Button, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { rectangle45 } from "./assets";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapperSetting>
        <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Box display="flex" alignItems="center" style={{ padding: "16px 12px" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  style={{ color: "#fff", backgroundColor: "#44403C", borderRadius: "8px" }} 
                >
                  <ArrowBackIcon />
                  </IconButton>
                  <Typography className="outer-heading">Settings</Typography>
              </Box>
              <Box className= "main-container">
                <Box className= "settings-container-1">
                  <Box className= "outer-box-container-1">
                    <Typography className= "inner-texts" variant="subtitle1" component="h2">How do you want to be notified?</Typography>
                    <Typography className= "inner-texts" variant="subtitle1" component="h2">Email Notifications: <Typography variant="body1" component="span">Comments Mentions</Typography></Typography>
                    <Typography className= "inner-texts" variant="subtitle1" component="h2">Push Notifications: <Typography variant="body1" component="span">Likes, Comments, Mentions, Communitiy</Typography></Typography>
                    <Box className= "button-container"><Button className="box-inner-button">Change Notifications</Button></Box>
                  </Box>
                </Box>
                <Box className= "settings-container-2">
                  <Box className="outer-box-container-2">
                  <Typography className="inner-texts" variant="subtitle1" component="h2">
                    Subscriptions
                  </Typography>
                  <Grid container spacing={2}>
                    {this.state.subscriptions.map((subscription, index) => (
                      <Grid item xs={4} key={index}>
                        <Box className= "subscription-container">
                          <img 
                            style={{ width: "60px", height:"60px" }}
                            src={rectangle45}
                          />
                          <Box style={{ flex: '1 0 auto', paddingLeft: "10px" }}>
                            <Typography variant="body1" className="inner-texts">
                              {subscription.name}
                            </Typography>
                            <Typography variant="body2" className="inner-texts">
                              {subscription.artist}
                            </Typography>
                          </Box>
                          <Box style={{ alignSelf: 'center', marginLeft: 'auto', fontWeight: 'bold' }}>
                            <Typography variant="body1" className="inner-texts">
                              {subscription.price}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box className= "button-container">
                    <Button variant="contained" className= "box-inner-button">
                      See All Subscriptions
                    </Button>
                  </Box>
                  </Box>
                </Box>
                <Box className= "settings-container-3">
                  <Box className= "outer-box-container-3">
                      <Typography className= "inner-texts" variant="h6" component="h2">Billing</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs>
                          <Typography className= "inner-texts" variant="subtitle1" component="h2">Free Resident Member Plan</Typography>
                          <Typography variant="body1" component="span" className= "inner-texts">Free Plan</Typography>
                        </Grid>
                        <Grid item xs className="grid-container">
                          <Box>
                            <Typography className= "inner-texts" variant="subtitle1" component="h2">Card Details</Typography>
                            <Typography variant="body1" component="span" style={{color: "#fff"}}>Visa ending on ****3235</Typography>
                          </Box>
                          <Box>
                            <Button variant="outlined" className= "inner-button">Edit</Button>
                          </Box>
                        </Grid>
                        <Grid item xs className="grid-container">
                          <Box>
                            <Typography className= "inner-texts" variant="subtitle1" component="h2">Address Information</Typography>
                            <Typography variant="body1" component="span" style={{color: "#fff"}}>Lorem Ipsum #03</Typography>
                          </Box>
                          <Box>
                            <Button variant="outlined" className= "inner-button">Edit</Button>
                          </Box>
                        </Grid>
                      </Grid>
                  </Box>
                </Box>
                <Box className= "settings-container-4">
                  <Box className= "outer-box-container-4">
                      <Typography className= "inner-texts" variant="h6" component="h2">Account</Typography>
                      <Grid container spacing={3}>
                        <Grid item xs>
                        <Button variant="outlined" className= "account-inner-button-1">Delete Account</Button>
                        </Grid>
                        <Grid item xs>
                        <Button variant="outlined" className= "account-inner-button">Terms & Conditions</Button>
                        </Grid>
                        <Grid item xs>
                        <Button variant="outlined" className= "account-inner-button">Privacy Policy</Button>
                        </Grid>
                      </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
        </Container>
      </StyledWrapperSetting>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapperSetting = styled("div")({
  margin: "0 auto",
  height: "auto",
  width: "100%",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .settings-container-1": {
    backgroundColor: "#44403C",
  },
  "& .settings-container-2": {
    backgroundColor: "#312F2D",  
  },
  "& .settings-container-3": {
    backgroundColor: "#030303",  
  },
  "& .settings-container-4": {
    backgroundColor: "#44403C",  
  },
  "& .outer-heading": {
      color: "white",
      fontSize: "30px",
      fontWeight: 900,
      lineHeight: "40px",
      letterSpacing: "-0.005em",
      textAlign: "left",
      paddingLeft: "16px", 
      paddingBottom: "16px",
  },
  "& .container":{ 
    backgroundPosition: "center",
    width: "1412px",
    height: "87px",
    padding: "50px 0px 0px 0px",
    flexDirection:"row"
  },
  "& .main-container": {
    width: "100%",
    height: "100%"
  },
  "& .outer-box-container-1": {
    width: "100%",           
    margin: "0 auto",        
    padding: "60px",           
    boxSizing: "border-box",
    maxWidth: "920px"
  },
  "& .outer-box-container-2": {
    width: "100%",           
    margin: "0 auto",        
    padding: "60px",         
    boxSizing: "border-box",
    maxWidth: "924px"
  },
 "& .outer-box-container-3": {
    width: "100%",           
    margin: "0 auto",        
    padding: "60px 60px 60px 60px", 
    boxSizing: "border-box",
    maxWidth: "932px",
    display: 'flex',        
    flexDirection: 'column', 
  },
  "& .outer-box-container-4": {
    width: "100%",           
    margin: "0 auto",        
    padding: "60px",         
    boxSizing: "border-box",
    maxWidth: "866px"
  },
  "& .subscription-container": {
    display: 'flex', 
    color: '#fff', 
    padding: '10px', 
    backgroundColor: "#312F2D", 
    border: "0px",
    borderRadius: '4px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
  },
  "& .grid-container": {
    display: "flex",
    justifyContent: 'space-between', 
    alignItems: 'center',           
    gap: "20px",                    
    padding: "20px 0",             
  },
  "& .inner-texts": {
    color: 'white',
    padding: '10px 0', 
    fontWeight: "bold",
    fontSize: '16px', 
  },
  "& .button-container": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .box-inner-button": {
    backgroundColor: "#FF0807",
    color: "white",
    borderRadius: "8px",
    width: "184px",
    height: "32px",
    margin: "6px",
    padding: "6px 10px 6px 10px",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  "& .inner-button": {
    color: "#fff", 
    borderColor: "#fff",
    textTransform: "capitalize", 
    fontWeight: "bold",
    padding: "10px 20px",   
    marginRight: '10px',
    width: "20px",
    height: "35.5px",
    borderRadius: "8px"
  },
  "& .account-inner-button": {
    color: "#fff", 
    borderColor: "#fff", 
    marginTop: "10px",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  "& .account-inner-button-1": {
    color: "#fff", 
    borderColor: "#fff",
    marginTop: "10px",
    textTransform: "capitalize",
    fontWeight: "bold"
  }
})
// Customizable Area End

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const signUp_background = require("../assets/signUp_background.png").default;
export const logoSvg = require("../assets/logoSvg.svg").default;
export const checkbox = require("../assets/checkbox.svg").default;
export const backCategories = require("../assets/categories_back.png").default;
export const image_art = require("../assets/image_art.png").default;
export const image_business = require("../assets/image_business_vector.png").default;
export const image_comedy = require("../assets/image_comedy.png").default;
export const image_education = require("../assets/image_education_vector.png").default;
export const image_fiction = require("../assets/image_fiction_Vector.png").default;
export const image_government = require("../assets/image_gbo.png").default;
export const image_history = require("../assets/image_history.png").default;
export const image_fitness = require("../assets/image_fitness.png").default;
export const image_family = require("../assets/image_family.png").default;
export const image_science = require("../assets/image_science.png").default;
export const image_lesiure = require("../assets/image_lesiure.png").default;
export const image_music = require("../assets/image_music.png").default;
export const image_news = require("../assets/image_news.png").default;
export const image_religion = require("../assets/image_religion.png").default;
export const image_film = require("../assets/image_film.png").default;
export const image_society = require("../assets/image_society.png").default;
export const image_sports = require("../assets/image_sports.png").default;
export const image_tech = require("../assets/image_tech.png").default;
export const image_truecrime = require("../assets/image_truecrime.png").default;
export const image_stuffs = require("../assets/image_stuffs.png").default;
export const image_socialLinks = require("../assets/image_socialLinks.png").default;
export const backgroundImg = require("../assets/backgroundImg.jpeg").default;
export const ForgotPasswordImg = require("../assets/ForgotPassword.png").default;
export const ForgotPasswordImg2 = require("../assets/ForgotPassword2.png").default;
export const visibility_icon = require("../assets/visibility_copy.svg").default;
export const visibility_cross = require("../assets/visibilityCross.svg").default;
export const checkBoxTicked = require("../assets/tickedCheckbox.png").default;
export const star = require("../assets/star_24px.png").default;

// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Button,
  Grid,
  TextField
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import IconButton from '@mui/material/IconButton';
import SetUpProfileThirdScreenControllerNoRss, {
  Props,
} from "./SetUpProfileThirdScreenControllerNoRss";
import CloseIcon from '@mui/icons-material/Close';
import { ResolveCondition, handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";
import { goBack } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";

export default class SetUpProfileThirdScreenNoRss extends SetUpProfileThirdScreenControllerNoRss {
  constructor(props: Props) {
    super(props);
  }
  validationSchema = Yup.object().shape({   
    first_name : Yup.string().required("Required"),
    last_name : Yup.string().required("Required"),
    email_address : Yup.string().required("Required"),
    location:  Yup.string().required("Required"),
  });
  render() {
    return (
      <>
      <NavigationMenu navigation={this.props.navigation} id='1' />
      <StyleWrapperSetupPodcastProfileThird>
        <Box className="subContain">

        </Box>
        <Box className="mainTitle">
          <img
            src={goBack}
            alt="goBack"
            data-test-id="navigate"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleNavigation("SetupPodcastProfileNoRssFeed", this.props);
            }}
          />
          Setup Podcast Profile
        </Box>
        <Box className="redContainer"></Box>
        <Grid className="container">
          <Grid className="subContainer">
            <Formik
              initialValues={{ hosts: [{ first_name: '', last_name: '', email_address: '', location: '' }] }}
              onSubmit={(values) => {
                console.log('Form values:', values);
              }}
              validationSchema={this.validationSchema}
              validateOnMount={true}
              enableReinitialize
            >
              {({ handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FieldArray name="hosts" data-test-id="hosts-field">
                    {({ push, remove }) => (
                      <>  
                        {values.hosts.map((host, index) => (
                          <Box key={index} mb={5} className={ResolveCondition(index >= 1,"newHostBg","")} 
                          sx={{ position: 'relative', padding: '20px' }}>
                            
                            <Box style={{width : "64%",margin:"auto"}}>
                            <Box className="heading">HOST #{index + 2}</Box>

                            {index > 0 && (
                              <IconButton
                              data-test-id="iconButton"
                                onClick={() => remove(index)}
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  color: 'white',
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                            
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <label className="label">
                                  Host First Name<span>*</span>
                                </label>
                                <Field
                                  name={`hosts[${index}].first_name`}
                                  as={TextField}                                  
                                  fullWidth
                                  sx={textFieldStyles}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label className="label">
                                  Host Last Name<span>*</span>
                                </label>
                                <Field
                                  name={`hosts[${index}].last_name`}
                                  as={TextField}
                                  fullWidth
                                  sx={textFieldStyles}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label className="label">
                                  Host Email Address<span>*</span>
                                </label>
                                <Field
                                  name={`hosts[${index}].email_address`}
                                  as={TextField}
                                  fullWidth
                                  sx={textFieldStyles}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label className="label">
                                  Host Location<span>*</span>
                                </label>
                                <Field
                                  name={`hosts[${index}].location`}
                                  as={TextField}
                                  fullWidth
                                  sx={textFieldStyles}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          </Box>
                        ))}
                        
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          data-test-id="addHost"
                          fullWidth
                          onClick={() => {                            
                            push({ first_name: '', last_name: '', email_address: '', location: '' });
                          }}
                          className={values.hosts.length >= 3 ? "hostBtnDisabled" : "hostBtn"}
                          disabled={values.hosts.length >= 3}
                        >
                          Add Another Host
                        </Button>

                        <Box style={{ textAlign: "center", color: "white", fontFamily: "Nexa-Heavy" }}>Maximum Number of Hosts: 4</Box>

                      </>
                    )}
                  </FieldArray>

                  <Button type="submit" variant="contained" color="primary" fullWidth className="continueButton">
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </StyleWrapperSetupPodcastProfileThird>
      <Footer navigation={this.props.navigation} id={"1"} />
      </>
    );
  }

}
const StyleWrapperSetupPodcastProfileThird = styled("div")({  
  paddingLeft: "calc(6% + 135px)",
  paddingRight : "5%",
  height: "auto",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .newHostBg": {
    backgroundColor: "#44403C"
  },
  "& .continueButton": {
    width: "392px",
    height: "52px",
    color: "white",
    textAlign: "center",
    display: "flex",
    textTransform: "capitalize",
    background: "#B2B3B3",
    margin: "auto",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "14px",
    marginTop: "3%",
    marginBottom: "7%",
    "&:hover":{
      color: "white",
      background: "#B2B3B3",
    }
  },
  "& .label": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
  },
  "& .heading": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "24px",
    marginTop: "2%",
    marginBottom: "4%"
  },
  "& .hostBtn": {
    height: "56px",
    width: "392px",
    background: "black",
    color: "white",
    textTransform: "capitalize",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    margin: "auto",
    fontSize: "14px",
    display: " flex",
    marginTop: "2%",
    marginBottom: "3%",
    "&:hover": {
      background: "black",
      color: "white"
    },
  },
  "& .hostBtnDisabled": {
    backgroundColor: "#B2B3B3 !important",
    color: "white !important",
    height: "56px",
    width: "392px",
    margin: "auto",
    fontSize: "14px",
    display: " flex",
    marginTop: "2%",
    marginBottom: "3%",
    textTransform: "capitalize",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
  },
  "& .field": {
    width: "954px",
    height: "56px",
  },
  "& .container": {
    height: "auto",
    backgroundColor: "#312F2D",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItens: "center",
    margin: "auto",
    borderRadius: "0px 0px 8px 8px"
  },
  "& .subContainer": {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    height: "auto",
    marginTop: "28px",
  },
  "& .redContainer": {
    height: "80px",
    margin: "auto",
    borderRadius: "0px 16px 0px 0px",
    backgroundColor: "#FF0807",
  },
  "& .mainTitle": {
    margin: "auto",
    height: "40px",
    // width: "90%",
    color: "white",
    fontFamily: "poppins",
    fontWeight: 900,
    fontSize: "30px",
    display: "flex",
    alignItems: "center",
    marginBottom : "21px"
  },
  "& .subContain": {   
    position: "relative",
    height: "47rem",
    paddingRight: "40px", 
    overflow: "hidden",     
    overflowY: "scroll",
    scrollbarColor: "#FF0807 #4D4D4D",
    scrollbarWidth: "thin", 
  },
})

const textFieldStyles = {
  marginTop: "2%",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#CBD5E1",
  },
  "& .MuiInputBase-input": {
    color: "#CBD5E1",
    backgroundColor: "black",
    "&:-webkit-autofill": {
      backgroundColor: "black !important",
      WebkitBoxShadow: "0 0 0 1000px black inset",
      WebkitTextFillColor: "#CBD5E1 !important",
    },
  },
};

// Customizable Area End

import React from "react";
// Customizable Area Start
import { logo, podcastImage, ad1, ad2, ad3 } from '../assets'
import AudioPlayerNew from "../components/AudioPlayerNew.web"

const WIDE_SCREENS = window.innerWidth > 1500
// Customizable Area End

import AudioLibraryController, {
  Props
} from "../AudioLibraryController";


export default class AudioPlayerFullScreen extends AudioLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div style={webStyle.wrapper}>
            <div style={webStyle.logo}>
                <img src={logo} alt="Logo" />
            </div>
            <div style={webStyle.content}>
                <div style={webStyle.details}>
                    <div>
                        <img src={podcastImage} width="500px" height="auto" alt="Podcast" />
                    </div>
                    <div style={{width: WIDE_SCREENS ? "620px" : "430px"}}>
                        <div 
                            style={{ 
                                fontFamily:"Nexa-Heavy", 
                                fontWeight: 900, 
                                fontSize: WIDE_SCREENS ? "50px" : "45px", 
                                color: "#f8fafc", 
                                lineHeight: WIDE_SCREENS ? "22px" : "40px"
                            }}>
                            Podcast Name #2
                        </div>
                        <br />
                        <div style={{ fontFamily:"Nexa-Regular", fontWeight: 100, fontSize: "30px", color: "#f8fafc", lineHeight: "22px" }}>
                            Artist name
                        </div>
                        <br />
                        {WIDE_SCREENS && 
                            <>
                                <div style={{ fontFamily:"Nexa-Heavy", fontWeight: 900, fontSize: "18px", color: "#f5f5f5" }}>
                                About
                                </div>
                                <div style={{ fontFamily:"Nexa-Regular", fontWeight: 100, fontSize: "14px", color: "#fff"}}>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiun, Sed ut perspiciatis unde omnis iste natus 
                                    error sit voluptatem accusantium doloremque laudantiun,Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque 
                                    laudantiun,Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiun,Sed ut perspiciatis unde omnis 
                                    iste natus error sit voluptatem accusantium doloremque laudantiun, Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
                                    doloremque laudantiun, Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu.
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantiun,Sed ut perspiciatis unde omnis iste natus error 
                                    sit voluptatem accusantium doloremque laudantiun. perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.
                                </div>
                                <br />
                                <div style={{ fontFamily:"Nexa-Heavy", fontWeight: 900, fontSize: "14px", color: "#fff" }}>
                                    ... Show more
                                </div>
                            </>
                        }
                        
                    </div>
                </div>
                <div style={webStyle.ads}>
                    <img src={ad1} />
                    <img src={ad2} />
                    <img src={ad3} />
                </div>
            </div>
            <div style={webStyle.audioPlayer}>
                <AudioPlayerNew />
            </div>
        </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    wrapper: {
        color: "white",
        display: "flex",
        flexDirection: "column" as const,
        minHeight: "100vh" as const,
        paddingLeft: "4%",
        paddingRight: "2%",
    },
    logo: {
        paddingTop: "2%",
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        flex: 1,
        alignItems: "center"
    },
    details: {
        display: "flex",
        gap: 20,
        alignItems: "center",
    },
    ads: {
        display: "flex",
        flexDirection: "column" as const,
        gap: 20
    },
    audioPlayer: {
        height: "auto",
    
    },
  };
  // Customizable Area End
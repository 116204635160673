// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Grid,
    Button,
} from "@mui/material";
import HostPaymentPlanController, {
    Props,
} from "./HostPaymentPlanController";
import { darkBg, lightBg } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";


const customPricing = "https://forms.zohopublic.com/podcasttown/form/FreePodcastStrategySessionForm/formperma/wCcjSM2RW3qE0TiKERkPz4A1e6Pj1x9Lz4XvuMOU_wk"

export default class HostPaymentPlan extends HostPaymentPlanController {
    constructor(props: Props) {
        super(props);
    }
   
    render() {
        return (
            <>
            <NavigationMenu navigation={this.props.navigation} id='1' />
            <StyleWrapperHostPaymentPlan>
                <Box className="scroll">
                <Grid className="container">
                    <Grid className="upper">
                        <Box className="title">Choose Your Pricing Model</Box>
                        <Box className="desc">Our pricing is straightforward and "All-Inclusive," giving you access to the full range of features, benefits, and our vibrant community at Podcast Town. For content networks looking for a tailored solution, connect with us. Our platform specialists will work closely with you to understand your unique needs and deliver a customized approach that aligns with your goals.</Box>
                    </Grid>
                    <Grid>
                        <Box className="lowerContainer">
                            <Box className="boxS box1">
                                <Box className="imageContainer">
                                    <img src={lightBg} alt="lightBg" className="img" />
                                </Box>
                                <Box className="label"><b>Simple "All-Inclusive" Pricing</b></Box>
                                <Box className="price">{this.state.annualPrice}$</Box>
                                <Box className="secondaryTitle"><span style={{fontWeight : "bold"}}><b>Hosts & Partners</b></span> <span style={{ fontWeight: "bold", fontStyle: "italic", fontFamily: "Nexa-Regular" }}>Annual Subscription</span></Box>
                                <hr className="horizontal" />
                                <a href="/HostPaymentDetails" style={{textDecoration : "none"}}><Button className="selectPlanBtn" style={{ background: "#FF0807" }}><b>Select Plan</b></Button></a>
                            </Box>

                            <Box className="boxS box2">
                                <Box className="imageContainer">
                                    <img src={darkBg} alt="lightBg" className="img" />
                                </Box>
                                <Box className="label" style={{color : "black"}}><b>Simple "All-Inclusive" Pricing</b></Box>
                                <Box className="price" style={{color : "black"}}>{this.state.monthlyPrice}$</Box>
                                <Box className="secondaryTitle" style={{color : "black"}}><span style={{fontWeight : 900}}><b>Hosts & Partners</b></span> <span style={{ fontWeight: "bold", fontStyle: "italic", fontFamily: "Nexa-Regular" }}>Monthly Subscription</span></Box>
                                <hr className="horizontal" />
                                <a href="/HostPaymentDetails" style={{textDecoration : "none"}}><Button className="selectPlanBtn" style={{ background: "#FF0807" }}><b>Select Plan</b></Button></a>
                            </Box>
                      
                            <Box className="boxS box3">
                                <Box className="imageContainer">
                                    <img src={lightBg} alt="lightBg" />
                                </Box>

                                <Box className="label"><b>Simple</b></Box>
                                <Box className="customPrice">Custom Pricing</Box>
                                <Box className="secondaryTitle"><span style={{fontWeight : 900,fontFamily:"Nexa-Heavy"}}><b>Enterprise &</b><br /> Networks</span> <span style={{fontWeight: "bold",fontFamily:"Nexa-Regular"}}>Customized Solutions</span></Box>
                                <hr className="horizontal" style={{color : "white"}} />
                                <Button className="selectPlanBtn" style={{ background: "black" }} data-test-id="customPricing"   onClick={() => this.openLink(customPricing)}><b>Get a Quote</b></Button>
                            </Box>

                        </Box>
                    </Grid>
                </Grid>

                </Box>
            </StyleWrapperHostPaymentPlan>
            <Footer navigation={this.props.navigation} id={"1"} />
            </>
            
        );
    }

}
const StyleWrapperHostPaymentPlan = styled('div')({
    margin: "0 auto",
    height: "auto",
    paddingRight: "5%",
    paddingLeft: "calc(6% + 135px)",
    background: "black",
    "& *": {
        boxSizing: "border-box",
    },
    "& .customPrice":{
        fontFamily : "Nexa-Heavy",
        color : "white",
        fontWeight : 900,
        lineHeight:1,
        textAlign:"center",
        fontSize:"60px",
        marginLeft : "auto",
        height : "136px"
    },
    "& .selectPlanBtn": {
        textTransform : "inherit",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "18px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        color: "#F5F5F5",
        cursor: "pointer",
        height : "56px",
        width : "277px",
        marginTop : "6%",
        borderRadius : "8px"
    },
    "& .horizontal": {
        width: "80%",
        textAlign: "center",
        marginTop : "1%",
        marginBottom : "3%"
    },
    "& .secondaryTitle": {    
        margin: "auto",
        marginTop: "6%",
        marginBottom: "8%",
        width: "277px",
        height: "65px",
        display: "flex",
        flexDirection: "column",
        color: "#F5F5F5",
        fontFamily: "Nexa-Heavy",
        fontSize: "24px",
        textAlign: "center",
        justifyContent: "center",
        lineHeight : 1,
    },   
    "& .price": {
        marginTop: "13%",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "60px",
        textAlign: "center",
    },
    "& .label": {
        textAlign: "center",
        width: "277px",
        height: "54px",
        margin: "auto",
        fontFamily: "Nexa-Regular",
        marginTop: "7%",
        display: "flex",
        justifyContent: "center",
        fontSize: "24px",
        fontWeight: 400
    },
    "& .imageContainer": {
        display: "flex",
        justifyContent: "center",
        marginTop: "10%"
    },
    "& .lowerContainer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width : "80%",
        margin : "auto",
        marginTop: "3%",
        marginBottom: "5%",
        paddingBottom : "5%",
        alignItems: "stretch", 
        "@media (max-width: 1200px)": {
            flexDirection: "column", 
            alignItems: "center",
        },
    },
    "& .boxS": {
        height: "533px",
        width: "357px",
        borderRadius: "26px",
        border: "1px solid #B2B3B3",
        "@media (max-width: 1200px)": {
            marginBottom: "20px", 
        },
    },
    "& .box1": {
        background: "black"
    },
    "& .box2": {
        background: "white"
    },
    "& .box3": {
        background: "#FF0807"
    },
    "& .title": {
        color: "white",
        fontWeight: 900,
        fontSize: "30px",
        fontFamily: "Nexa-Heavy",
    },
    "& .desc": {
        margin: "auto",
        marginTop: "2%",
        marginBottom : "3%",
        fontWeight: 100,
        fontSize: "16px",
        fontFamily: "Nexa-Regular",
        width: "88%"
    },
    "& .upper": {
        height: "25%",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "5%",
        margin: "auto",
        textAlign: "center"
    },
    "& .container": {
        color: "white",
        height : "auto",
        margin: "auto",
        background: "#44403C",
        "@media (max-width: 1200px)": {
            height: "auto",
        },     
    },
    "& .scroll":{
        position: "relative",
        height: "44rem",
        paddingRight: "40px", 
        overflow: "hidden",     
        scrollbarWidth: "thin", 
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
    }

})
// Customizable Area End

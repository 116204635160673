import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { AppContext } from "./context/AppContext";
export type MediaList = Media[]

export interface Media {
  itmid: string
  type: string
  attributes: { file_info: FileInfo[] }
}

export interface FileInfo {
  title: string
  description: string
  focus_areas: string[]
  urldoc: string
  content_type: string
  text_file_to_str: string
  file_content: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  mediaList: MediaList;
  header: string;
  loading: boolean;
  documentModal: boolean;
  docUrl: string;
  videoModal: boolean;
  videoUrl: string;
  media: {
    nowPlaying: string;
    duration: number;
    progress: number;
  };
  mediaType: string;
  pageNo: number;
  limit: number;
  totalCount: number;
  currentPageNo: number;
  audioList: MediaList;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  screenId: string;
  // Customizable Area End
}

export default class AudioLibraryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    // Customizable Area End
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      mediaList: [],
      audioList: [],
      header: "Lets listen to some audios to make yourself feel better.",
      loading: false,
      documentModal: false,
      docUrl: "",
      videoModal: false,
      videoUrl: "",
      media: {
        nowPlaying: "",
        duration: 0,
        progress: 0
      },
      mediaType: 'audio',
      pageNo: 0,
      limit: 7,
      totalCount: 0,
      currentPageNo: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      if (apiRequestCallId === this.getMediaApiCallId) {
        if (responseJson && responseJson?.data) {
          this.setState(prev => ({
            totalCount: responseJson?.data[0].attributes?.count,
            audioList: responseJson?.data,
            mediaList: (prev.pageNo === 0) ?
              responseJson?.data :
              [...this.state.mediaList, ...responseJson?.data] || [],
            loading: false
          }));
        }
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  static contextType? = AppContext;

  async componentDidMount(): Promise<void> {

    this.getMedia();


    const msag: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msag);
  }

  handlePagination = (event: Object, value: number) => {
    this.setState({ currentPageNo: value });
    this.getMedia(value);
  }

  networkRequest = ({
    endPoint,
    method,
    headers,
    body,
    newState
  }: {
    endPoint: string;
    headers?: Record<string, string>;
    body?: Record<string, string>;
    method: "GET" | "POST" | "PUT" | "DELETE";
    newState?: Partial<S>;
  }) => {
    if (typeof newState === "object") {
      this.setState(prev => ({ ...prev, ...newState }));
    }
    const defaultHeaders = {
      'Content-Type': configJSON.exampleApiContentType,
      token: (this.context as any).state?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers || defaultHeaders)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getMedia = (currentPageNo?: number) => {
    let strBody = {
      limit: `${this.state.limit}`,
      page: `${currentPageNo || this.state.pageNo}`
    }
    let urlParams = new URLSearchParams(strBody).toString();
    this.getMediaApiCallId = this.networkRequest({
      endPoint: `audio_list?${urlParams}`,
      method: "POST",
      newState: { loading: true }
    });
  }

  fetchMoreData = () => {
    if (
      (this.state.pageNo >= this.state.totalCount) ||
      this.state.loading
    ) {
      return;
    }
    this.setState(prevState => ({
      pageNo: prevState.pageNo + 1
    }), () => this.getMedia());
  }

  goBackcalling = () => {
    this.props.navigation.goBack()
  }

  // Customizable Area End
}

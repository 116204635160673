// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { FormikErrors } from "formik";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S { 
  enablePasswordField : boolean;
  enableConfirmPasswordField : boolean;
  isModalOpen : boolean; 
  setFormikError: (errors: FormikErrors<{ [field: string]: any }>) => void;
}

export interface SS {
  id: any;  
}

export default class EmailForgotPasswordControllerScreenSecond extends BlockComponent<
  Props,
  S,
  SS
> {  
  resetPasswordApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {  
      enablePasswordField  : true,
      enableConfirmPasswordField : true,
      isModalOpen : false,
      setFormikError:()=>{},
    };
  } 

  handleClickShowPassword = () => {
    this.setState(prevState => ({
      enablePasswordField: !prevState.enablePasswordField
  }));
  }
  handleClickShowConfirmPassword = () => {
    this.setState(prevState => ({
      enableConfirmPasswordField: !prevState.enableConfirmPasswordField
  }))
  }

  handleClose = () =>{
    this.setState({isModalOpen : false})
  }

  async receive(from: string, message: Message) {    
    this.handleResForForgotPasswordApi(from,message);     
  }

  resetPassword = async(values : any,setErrors:(errors: FormikErrors<{ [field: string]: any }>) => void) => {    
    this.setState({
      setFormikError:setErrors
    })  
    const queryParams = new URLSearchParams(window.location.search);
    const tokenParam = queryParams.get('token');        
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };    
    const httpBody = 
   {
     "password": values.password,
     "password_confirmation": values.confirm_password,
     "token": tokenParam 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPassword
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForForgotPasswordApi = async (from: string, message: Message) => {    
    if (this.resetPasswordApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));            
      if (responseJson.status == "success") {        
        this.setState({isModalOpen : true})        
      }       
    }
  };
}
// Customizable Area End

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { FormikErrors } from "formik";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S { 
  isModalOpen : boolean;
  forgotPasswordResponse : any;
  setFormikError: (errors: FormikErrors<{ [field: string]: any }>) => void;
  emailAddress : string
}

export interface SS {
  id: any;  
}

export default class EmailForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {  

  forgotPasswordApiCallId = ""

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {  
      isModalOpen : false  ,
      forgotPasswordResponse : {}  ,
      setFormikError:()=>{},
      emailAddress : ''
    };
  } 
  
  async receive(from: string, message: Message) {    
    this.handleResForForgotPasswordApi(from,message);     
  }

  handleClose = () =>{
    this.setState({isModalOpen : false})
  }


  handleResendLink = () => {
    const { emailAddress, setFormikError } = this.state;       
  if (emailAddress && setFormikError) {
    this.forgotPassword({ email_address: emailAddress }, setFormikError);
  }
  };

  forgotPassword = (values : any,setErrors:(errors: FormikErrors<{ [field: string]: any }>) => void) => {   
    this.setState({
      setFormikError:setErrors
    })  
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };    
    const httpBody = 
    {
      "data": {
        "type": "email_account",
        "attributes": {
          "email": values.email_address        
        }
      }
    }    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPassword
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForForgotPasswordApi = async (from: string, message: Message) => {    
    if (this.forgotPasswordApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        
      if (responseJson.token) {
        this.setState({isModalOpen : true})        
      }      
    }
  };

}
// Customizable Area End

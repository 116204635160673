import React, {useState, useRef, useEffect} from "react";
import { 
  cover,
  addBtn,
  heart,
  prev,
  next,
  nextsec,
  volumeIcon,
  size,
  backsec,
  comments,
  shares,
  like,
  pauseIcon,
  playBtn
} from "../../src/assets"
import { useNavigate } from "react-router-dom";


const audio1 = require("../../assets/audio1.mp3").default
const audio2 = require("../../assets/audio2.mp3").default
const audio3 = require("../../assets/audio3.mp3").default

const WIDE_SCREENS = window.innerWidth > 1500

const AudioPlayerNew: React.FC = (): JSX.Element =>{
    const [speed, setSpeed] = useState<number>(1);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [currentTrack, setCurrentTrack] = useState(0);
    const navigate = useNavigate();

    const audioTracks = [
        { title: 'Podcast Name #1', artist: 'Artist Name #1', src: audio1 },
        { title: 'Podcast Name #2', artist: 'Artist Name #2', src: audio2 },
        { title: 'Podcast Name #3', artist: 'Artist Name #3', src: audio3 },
    ]

const handlePlayPause = () => {
  if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
  }
};

const handleFullScreenClick = () => navigate("/AudioPlayerFullScreen")


  const handleTimeUpdate = () => {
    if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
      }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) { 
        setDuration(audioRef.current.duration);
      }
  };

  const handleSpeedChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSpeed = parseFloat(e.target.value);
    setSpeed(selectedSpeed);
    if (audioRef.current) {
        audioRef.current.playbackRate = selectedSpeed;
      }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleTrackChange = (direction: any) => {
    let newTrackIndex = currentTrack + direction;

    if (newTrackIndex >= audioTracks.length) {
      newTrackIndex = 0;
    } else if (newTrackIndex < 0) {
      newTrackIndex = audioTracks.length - 1;
    }

    setCurrentTrack(newTrackIndex);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load()
      audioRef.current.playbackRate = speed

      if (isPlaying) {
        audioRef.current.play();
      }
    }
  }, [currentTrack, speed, isPlaying]);

  return(
    <div style={styles.playerWrapper} >
      <div style={styles.podcastWrapper} >
        <img src={cover} width="76px" height="74px" alt="Cover" />
        <div style={styles.podcastDetails} >
          <div style={{fontSize: "16px", fontWeight: 900, color: "#F8FAFC"}}>Podcast Name #2</div>
          <div style={{fontSize: "16px", fontWeight: 100, color: "#F8FAFC"}}>Artist name</div>
          { !WIDE_SCREENS && 
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <img src={heart} />
              <img src={addBtn} />
            </div>
          }
        </div>
        { WIDE_SCREENS && 
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <img src={heart} />
            <img src={addBtn} />
          </div>
        }
      </div>
      <div style={styles.playerIcons} >
        <div style={{display: "flex", gap: 15, alignItems: "center"}} >
          <img src={backsec} />
          <img data-test-id="prevClick" src={prev} onClick={() => handleTrackChange(-1)} />
          {isPlaying ? 
            <img src={pauseIcon} data-test-id="pauseIcn" onClick={handlePlayPause} /> : 
            <img src={playBtn} data-test-id="playIcn"  onClick={handlePlayPause} width="44px" height="43px" />}
          <img src={next} data-test-id="nextIcn"  onClick={() => handleTrackChange(1)} />
          <img src={nextsec} />
          <select
            style={styles.select}
            value={speed}
            onChange={(e) => {handleSpeedChange(e)}}>
            <option style={{ fontWeight: 900 }} value="1">1x</option>
            <option style={{ fontWeight: 900 }} value="1.5">1.5x</option>
            <option style={{ fontWeight: 900 }} value="2">2.x</option>
          </select>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
          <div data-test-id="currentTimeDisplay" style={styles.time}>{formatTime(currentTime)}</div>
          <div style={{ width: "460px", height: "4px", backgroundColor: "grey", borderRadius: "2px", overflow: "hidden" }}>
            <div style={{ width: `${(currentTime / duration) * 100}%`, height: '100%', backgroundColor: '#FF0807' }}></div>
          </div>
          <div style={styles.time}>{formatTime(duration)}</div>
        </div>
        { !WIDE_SCREENS && 
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 60}}>
          <div style={{display: "flex",  alignItems: "center", gap: 10}}>
            <img src={like} />
            <div style={{...styles.count, color: "#FF0807"}}>1.3K</div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <img src={comments} />
            <div style={styles.count}>994</div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <img src={shares} />
            <div style={styles.count}>1.2K</div>
          </div>
        </div>
      }
      </div>
      { WIDE_SCREENS && 
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 30}}>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 2}}>
            <img src={like} />
            <div style={{...styles.count, color: "#FF0807"}}>1.3K</div>
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 2}}>
            <img src={comments} />
            <div style={styles.count}>994</div>
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 2}}>
            <img src={shares} />
            <div style={styles.count}>1.2K</div>
          </div>
        </div>
      }
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", color: "#fff"}} >
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
          <img src={volumeIcon} />
          <div style={{ width: "100px", height: "4px", backgroundColor: "grey", borderRadius: "2px", overflow: "hidden"}}>
            <div style={{ width: "50%", height: "100%", backgroundColor: "#FFF" }}></div>
          </div>
          <img src={size} style={{cursor: "pointer"}} onClick={() => handleFullScreenClick()} />
        </div>
      </div>

      <audio
        ref={audioRef}
        src={audioTracks[currentTrack].src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => handleTrackChange(1)}
      />
    </div>
  )
}

export default AudioPlayerNew

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100vh',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
  },
  playerWrapper: {
    display: "flex", 
    justifyContent: "space-between",
    alignItems: "center", 
    padding: "16px 5% 16px 0",
    width: "95%", 
  },
  podcastWrapper: {
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center", 
    gap: 15
  },
  podcastDetails: {
    display: "flex", 
    flexDirection: "column" as const, 
    color: "white"
  },
  playerIcons: {
    display: "flex", 
    flexDirection: "column" as const, 
    justifyContent: "center" as const,
    alignItems: "center" as const,
  
  },
  select: {
    backgroundColor: 'black', 
    color: 'white', 
    border: "none", 
    fontWeight: 900, 
    borderRadius: 8, 
    fontSize: "16px", 
    padding: "8px 0"
  },
  time: {
    color: "#F8FAFC",
    fontWeight: 700 
  },
  count: {
    fontWeight: 900,
    fontSize: 14,
    color: "#fff",
  }
};